import queryString from 'query-string'
import { batch } from 'react-redux'
import { toast } from 'react-toastify'

import { startCase } from 'lodash'
import { apiService, fetchingAPI } from '@/api'
import { setLoading, setUserPermissionColumns, setUsers } from './organizationUsers.redux'
import { updateUserPermissions } from '../auth/currentUser.redux'

// TODO: Refactor toggleLoading, update to async/await
export const fetchUsers =
  ({ queryParam, toggleLoading, organizationId, dispatchAction }) =>
  (dispatch) => {
    const loadUsersQueryString =
      queryParam && queryParam !== 'active'
        ? `?${queryString.stringify({ [queryParam]: true })}`
        : ''
    const loadUsers = fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users${loadUsersQueryString}`,
      'GET',
      dispatch
    )

    Promise.all([loadUsers])
      .then(([{ users, edit_config, edit_users }]) => {
        batch(() => {
          dispatch({
            type: dispatchAction || 'organizations/setUsers',
            payload: users,
          })
          dispatch(updateUserPermissions({ edit_config, edit_users }))
        })

        if (toggleLoading) toggleLoading()
      })
      .catch((err) => {
        console.error('fetchUsers failed', err)
        if (toggleLoading) toggleLoading()
      })
  }

// TODO: Refactor toggleLoading
export const createUser =
  ({ organizationId, user, updateUsers, toggleLoading }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/users`,
        'POST',
        dispatch,
        JSON.stringify(user)
      )
      updateUsers(organizationId)
      toggleLoading()
    } catch (err) {
      toast.error(`Failed to create user. ${err?.error_message ? err.error_message : ''}`)
    }
  }

// TODO: Refactor toggleLoading
export const updateUser =
  ({ userId, userData, loadUsersData, queryParam, toggleLoading }) =>
  async (dispatch, getState) => {
    const userDataToSubmit = { ...userData }

    // if tag objects are passed instead of ids, then convert object into ids.
    if (userDataToSubmit?.tags?.length > 0 && typeof userDataToSubmit.tags[0] === 'object') {
      userDataToSubmit.tags = userDataToSubmit.tags.map((tag) => tag && tag.id)
    }

    try {
      const { organization_id } = await fetchingAPI(
        `${apiService.web}/api/users/${userId}`,
        'PATCH',
        dispatch,
        JSON.stringify(userDataToSubmit)
      )
      const oldUsers = getState().organizationUsers.users
      const users = oldUsers.map((user) =>
        user.id === userId ? { ...userData, tags: user.tags } : user
      )
      dispatch(setUsers(users))
      dispatch(loadUsersData({ organizationId: organization_id, queryParam, toggleLoading }))
    } catch (err) {
      toast.error(`Failed to update user. ${err?.error_message ? err.error_message : ''}`)
      if (toggleLoading) toggleLoading()
    }
  }

// TODO: Refactor toggleLoading
export const restoreUser =
  ({ userId, organizationId, loadUsersData, toggleLoading, queryParam }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(`${apiService.web}/api/users/${userId}`, 'POST', dispatch)

      dispatch(loadUsersData({ organizationId, queryParam, toggleLoading }))
    } catch (err) {
      // catching error
    }
  }

// TODO: Refactor toggleLoading, update to async/await
export const exportUsers =
  ({ organizationId, data, toggleLoading }) =>
  (dispatch) => {
    const body = JSON.stringify(data)
    toggleLoading()
    fetchingAPI(`${apiService.web}/api/export/${organizationId}/users`, 'POST', dispatch, body)
      .then((csvStream) => {
        // get stream reader from fetch response body stream
        const responseReader = csvStream.getReader()
        const streamProgress = { data: '' }
        // this function reads stream, then if done == true we download and clean up.
        const readStream = () =>
          responseReader.read().then(({ value, done }) => {
            if (done) {
              const encodedUri = URL.createObjectURL(
                new Blob([streamProgress.data], { type: 'text/csv' })
              )
              const link = document.createElement('a')
              link.setAttribute('href', encodedUri)
              link.setAttribute('download', 'users.csv')
              document.body.appendChild(link) // Required for FF
              link.click()
              document.body.removeChild(link)

              return toggleLoading()
            }
            // stream comes in as uint8array
            const decodedCsvData = new TextDecoder('utf-8').decode(value)
            streamProgress.data += decodedCsvData

            return readStream()
          })
        readStream()
      })
      .catch(() => {
        toggleLoading()
      })
  }

export const fetchUserPermissions = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ userPermissions: true }))

  try {
    const permissions = await fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/user-permissions`,
      'GET',
      dispatch
    )

    const formattedColumns = permissions.map((column) => {
      const makeLabel = (column, _label) => {
        return { label: _label, value: column }
      }

      switch (column) {
        case 'code':
          return makeLabel('voip_user_id', 'VOIP User ID')
        case 'edit_config':
          return makeLabel('playbook_edit_access', 'Playbook Edit Access')
        case 'edit_users':
          return makeLabel('user_management_access', 'User Management Access')
        case 'username':
          return makeLabel(column, 'Email')
        case 'deleted':
          return makeLabel('user_status', 'User Status')
        case 'config_cid':
          return makeLabel('playbook', 'Playbook')
        default:
          if (column.startsWith('edit_') && !column.endsWith('_access')) {
            return makeLabel(column, `Edit ${startCase(column.split('edit_')[1])} Access`)
          }
          return makeLabel(column, startCase(column))
      }
    })
    formattedColumns.push({ label: 'Tags', value: 'tags' })

    dispatch(setUserPermissionColumns(formattedColumns))
  } catch (err) {
    console.error('fetchUserPermissions failed', err)
  } finally {
    dispatch(setLoading({ userPermissions: false }))
  }
}
