import React from 'react'
import classNames from 'classnames'

import './Pill.scss'

export const Pill = ({
  brand,
  success,
  warning,
  caution,
  critical,
  informative,
  contrast,
  magical,
  header,
  icon,
  alignIcon = 'left',
  emphasized,
  large,
  small,
  circular,
  content,
  children,
  className,
  clickable,
  transparent,
  dataTestId,
  ...props
}) => {
  return (
    <div
      data-testid={dataTestId || 'pill'}
      className={classNames('status-pill', className, {
        brand,
        success,
        warning,
        caution,
        critical,
        informative,
        contrast,
        magical,
        icon,
        emphasized,
        large,
        small,
        circular,
        clickable,
        transparent,
      })}
      {...props}
    >
      {alignIcon !== 'right' && icon}
      {content || children}
      {alignIcon === 'right' && icon}
    </div>
  )
}
