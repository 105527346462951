import React from 'react'

export const AdvancedTableCell = ({ row, column }) => {
  if (column.clickableRowLink) {
    return (
      <a
        href={`/${column.clickableRowLink.prefix}/${row[column.clickableRowLink.accessor]}${
          column.clickableRowLink.suffix ? column.clickableRowLink.suffix : ''
        }`}
        className="table-link"
      >
        {row[column.accessor]}
      </a>
    )
  }

  if (column.format) {
    return column.format(row[column.accessor], row)
  }

  if (column.sub_content_accessor) {
    return (
      <div>
        <div>{row[column.accessor]}</div>
        <div className="muted-text small-text">{row[column.sub_content_accessor]}</div>
      </div>
    )
  }

  return row[column.accessor] || null
}
