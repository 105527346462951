import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Segment } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { capitalize } from 'lodash'

import { Pill } from '@/components/pills/Pill'
import {
  getAndCheckOrgColumn,
  getConnectorDisplayType,
  getStatusClassName,
  mergeOrgsandIntegrations,
} from '@/views/Integrations/helpers'
import { Link, useHistory } from 'react-router-dom'
import { IntegrationsForm } from '@/views/Integrations/IntegrationsForm'
import { deleteIntegration, fetchIntegrations } from '@/reducers/integrations/integrations.actions'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { apiService, fetchingAPI } from '@/api'
import { formatTime } from '@/utils/helpers'
import ConfirmForm from '../../components/forms/ConfirmForm'
import Form from '../../components/Modal'

const IntegrationsPage = () => {
  const dispatch = useDispatch()
  const { integrations, loading } = useSelector((state) => state.integrations)
  const organizations = useSelector((state) => state.organizations)
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchIntegrations())
  }, [])

  const refreshUniqueOutcomesMaterializedView = async (dispatch) => {
    try {
      toast.success(`Refreshing outcomes, be patient...`)
      await fetchingAPI(
        `${apiService.web}/api/integration/unique_outcomes_refresh`,
        'POST',
        dispatch
      )
      toast.success(`Successfully refreshed outcomes`)
    } catch (err) {
      console.error(err)
      toast.error(`Error refreshing outcomes`)
    }
  }

  const columns = [
    {
      accessor: 'name',
      isSearchable: true,
      label: 'Name',
    },
    {
      accessor: 'type',
      isSearchable: true,
      label: 'Connector Type',
      format: getConnectorDisplayType,
    },
    {
      accessor: 'organization_name',
      label: 'Organization',
      isSearchable: true,
    },
    {
      accessor: 'organization_id',
      label: 'Org Id',
      isSearchable: false,
    },
    {
      accessor: 'status',
      label: 'Status',
    },
    {
      accessor: 'modified_date',
      label: 'Last Updated',
      format: (value) => formatTime(value),
    },
  ]

  const actions = [
    {
      label: 'Delete',
      trigger: (row) => (
        <Form
          popupContent="Delete Integration"
          header="Delete Integration"
          icon="trash"
          muted
          modalSize="tiny"
          form={
            <ConfirmForm
              bodyText={`Are you sure you would like to delete [${row.name.value}]'s [${row.type}] integration config?`}
              primaryButtonText="Delete"
              danger
              handleSubmit={() => {
                if (row.active) {
                  toast.error(`Must be deactivated before deletion`)
                } else {
                  dispatch(deleteIntegration({ id: row.id, push: history.push }))
                }
              }}
            />
          }
        />
      ),
    },
  ]

  const rows = mergeOrgsandIntegrations(integrations, organizations).map((integration) => ({
    ...integration,
    name: {
      as: (
        <Link to={`/integrations/edit_config/${integration.id}`} className="table-link">
          {' '}
          {integration.name}
        </Link>
      ),
      value: integration.name,
    },
    status: {
      as: (
        <Pill small {...getStatusClassName(integration.status)}>
          {capitalize(integration.status.replace('_', ' '))}
        </Pill>
      ),
      value: integration.status,
    },
    organization_id: {
      as: getAndCheckOrgColumn(integration.organization_id),
      value: integration.organization_id,
    },
  }))
  return (
    <>
      <header className="page-header" data-testid="integrations-page">
        <h1>Integrations</h1>
        <ButtonAndFormModal
          buttonLabel="Create Integration"
          modalTitle="Create Integration"
          modalId="integrations/create"
          modalProps={{ size: 'tiny' }}
          buttonProps={{ primary: true }}
          form={<IntegrationsForm />}
        />
        <Button primary onClick={() => dispatch(refreshUniqueOutcomesMaterializedView)}>
          Refresh Unique Outcomes
        </Button>
      </header>

      <Segment className="not-padded">
        <AdvancedTable
          defaultOrderBy="name"
          loading={loading}
          columns={columns}
          rows={rows}
          actions={actions}
          stickyAction
          pagination
        />
      </Segment>
    </>
  )
}

export default IntegrationsPage
