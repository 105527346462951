// Action Types
export const SET_HAMTAR_RECOMMENDATIONS = 'recommendations/setHamtarRecommendations'
export const SET_TASC_RECOMMENDATIONS = 'recommendations/setTascRecommendations'
export const SET_LOADING = 'recommendations/setLoading'
export const SET_DYNAMIC_PROMPT_SCORES = 'recommendations/setDynamicPromptScores'
export const SET_DYNAMIC_PROMPTS_LOADING = 'recommendations/setDynamicPromptsLoading'
export const SET_RECOMMENDATIONS_CID = 'recommendations/setRecommendationsCid'

// Action Creators
export const setHamtarRecommendations = (payload) => ({ type: SET_HAMTAR_RECOMMENDATIONS, payload })
export const setTascRecommendations = (payload) => ({ type: SET_TASC_RECOMMENDATIONS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setDynamicPromptScores = (payload) => ({ type: SET_DYNAMIC_PROMPT_SCORES, payload })
export const setDynamicPromptsLoading = (payload) => ({
  type: SET_DYNAMIC_PROMPTS_LOADING,
  payload,
})
export const setRecommendationsCid = (payload) => ({ type: SET_RECOMMENDATIONS_CID, payload })

export const initialState = {
  recommendationsCid: null,
  hamtarRecommendations: [],
  tascRecommendations: [],
  dynamicPromptScoresLoading: false,
  dynamicPromptScores: [],
  loading: false,
}

export default function recommendationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HAMTAR_RECOMMENDATIONS:
      return { ...state, hamtarRecommendations: action.payload }
    case SET_TASC_RECOMMENDATIONS:
      return { ...state, tascRecommendations: action.payload }
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case SET_DYNAMIC_PROMPT_SCORES:
      return { ...state, dynamicPromptScores: action.payload }
    case SET_DYNAMIC_PROMPTS_LOADING:
      return { ...state, dynamicPromptScoresLoading: action.payload }
    case SET_RECOMMENDATIONS_CID:
      return { ...state, recommendationsCid: action.payload }
    default:
      return state
  }
}
