import produce from 'immer'
import { commandCenterSort } from './commandCenter.helpers'

// Action Types
export const CONNECT = 'commandCenter/connect'
export const DISCONNECT = 'commandCenter/disconnect'
export const RECEIVE_BROADCAST = 'commandCenter/receiveBroadcast'
export const SET_AGENTS = 'commandCenter/setAgents'
export const SET_AGENT = 'commandCenter/setAgent'
export const SET_SENTIMENT_DATA = 'commandCenter/setSentimentData'
export const SET_SENTIMENT_CONFIG = 'commandCenter/setSentimentConfig'
export const SET_LOADING = 'commandCenter/setLoading'
export const SET_WIDGET = 'commandCenter/setWidget'
export const ADD_CATEGORY = 'commandCenter/addCategory'
export const START_LISTENING = 'commandCenter/startListening'
export const ATTEMPT_LISTENING = 'commandCenter/attemptListening'
export const STOP_LISTENING = 'commandCenter/stopListening'

// Action Creators
export const connectCCSocket = () => ({ type: CONNECT })
export const disconnectCCSocket = () => ({ type: DISCONNECT })
export const setAgents = (payload) => ({ type: SET_AGENTS, payload })
export const setAgent = (payload) => ({ type: SET_AGENT, payload })
export const setSentimentData = (payload) => ({ type: SET_SENTIMENT_DATA, payload })
export const setSentimentConfig = (payload) => ({ type: SET_SENTIMENT_CONFIG, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setWidget = (payload) => ({ type: SET_WIDGET, payload })
export const addCategory = (payload) => ({ type: ADD_CATEGORY, payload })
export const stopListeningToCall = () => ({ type: STOP_LISTENING })
export const attemptListeningToCall = (payload) => ({ type: ATTEMPT_LISTENING, ...payload })
export const startListeningToCall = (payload) => ({ type: START_LISTENING, ...payload })

// State
export const initialState = {
  agents: [],
  sentiment: {},
  sentimentConfig: {},
  widgets: {
    objectives: [],
    categories: {},
  },
  loading: {
    agents: false,
    sentiment: false,
    sentimentUpsetReasons: false,
    sentimentPositiveReasons: false,
    sentimentConfig: false,
  },
  listeningToAgent: '',
}

// Reducer
const commandCenterReducer = (state = initialState, action) => {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_AGENTS:
        newState.agents = commandCenterSort(payload)
        break
      case SET_AGENT:
        newState.agents = commandCenterSort(
          state.agents.map((agent) => {
            if (agent.agent_id === payload.agentId) {
              return payload.agentData
            }

            return agent
          })
        )
        break
      case SET_SENTIMENT_DATA:
        newState.sentiment = payload
        break
      case SET_SENTIMENT_CONFIG:
        newState.sentimentConfig = payload
        break
      case SET_WIDGET:
        newState.widgets = { ...newState.widgets, ...payload }
        break
      case ADD_CATEGORY:
        newState.widgets.categories = { ...state.widgets.categories, ...payload }
        break
      case SET_LOADING:
        newState.loading = { ...newState.loading, ...payload }
        break
      case START_LISTENING:
        newState.listeningToAgent = action.agentUsername
        break
      case STOP_LISTENING:
        newState.listeningToAgent = ''
        break
      default:
        break
    }
  })
}

export default commandCenterReducer
