import activityLog from './realtime/activityLog/activityLog.redux'
import analytics from './analytics/analytics.redux'
import baltoGPT from './baltoGPT/baltoGPT.redux'
import callSearch from './callSearch/callSearch.redux'
import commandCenter from './commandCenter/commandCenter.redux'
import currentOrganization from './auth/currentOrganization.redux'
import currentUser from './auth/currentUser.redux'
import customBranding from './customBranding/customBranding.redux'
import gamification from './gamification/gamification.redux'
import insightEvents from './insights/insightEvents.redux'
import integrations from './integrations/integrations.redux'
import keywords from './keywords/keywords.redux'
import leaderboards from './leaderboards/leaderboards.redux'
import organization from './organizations/organization.redux'
import organizationCustomBranding from './organizations/organizationCustomBranding.redux'
import organizations from './organizations/organizations.redux'
import organizationTagCategories from './organizations/organizationTagCategories.redux'
import organizationTagManager from './organizations/organizationTagManager.redux'
import organizationTags from './organizations/organizationTags.redux'
import organizationUsers from './organizations/organizationUsers.redux'
import organizationUsersCSV from './organizations/organizationUsersCSV.redux'
import organizationVoipCampaigns from './organizations/organizationVoipCampaigns.redux'
import orgHierarchy from './orgHierarchy/orghierarchy.redux'
import playbook from './playbooks/playbook.redux'
import playbooks from './playbooks/playbooks.redux'
import playlists from './playlists/playlists.redux'
import prompts from './prompts/prompts.redux'
import qaCopilot from './qa-copilot/qa-copilot.redux'
import realtimeAdvancedExports from './realtime/advancedExports/advancedExports.redux'
import realtimeChats from './realtime/realtimeChats.redux'
import realtimeCoaching from './realtime/realtimeCoaching/realtimeCoaching.redux'
import realtimeNewAlertConfiguration from './realtime/alerts/realtimeNewAlertConfiguration.redux'
import realtimeOrganizationAlertConfigurations from './realtime/alerts/realtimeOrganizationAlertConfigurations.redux'
import realtimeReports from './realtime/reports/realtimeReports.redux'
import realtimeSocket from './realtime/realtimeSocket.redux'
import realtimeUserAlerts from './realtime/alerts/realtimeUserAlerts.redux'
import recommendations from './recommendations/recommendations.redux'
import routeErrors from './errors/routeErrors.redux'
import scorecards from './scorecards/scorecards.redux'
import ui from './ui/ui.redux'
import userSettings from './userSettings/userSettings.redux'
import baltoAcademy from './balto-academy/balto-academy.redux'

// TODO: Use the playbooks reducer and remove configs
const configs = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_CONFIGS':
      return action.configs.slice(0)
    default:
      return state
  }
}

const reducers = {
  activityLog,
  analytics,
  baltoGPT,
  callSearch,
  commandCenter,
  configs, // TODO: Remove. Used in OrganizationPage, TagPage
  currentOrganization,
  currentUser,
  customBranding,
  gamification,
  insightEvents,
  integrations,
  keywords,
  leaderboards,
  organization,
  organizationCustomBranding,
  organizations,
  organizationTagCategories,
  organizationTagManager,
  organizationTags,
  organizationUsers,
  organizationUsersCSV,
  organizationVoipCampaigns,
  orgHierarchy,
  playbook,
  playbooks,
  playlists,
  prompts,
  qaCopilot,
  realtimeAdvancedExports,
  realtimeChats,
  realtimeCoaching,
  realtimeNewAlertConfiguration,
  realtimeOrganizationAlertConfigurations,
  realtimeReports,
  realtimeSocket,
  realtimeUserAlerts,
  recommendations,
  routeErrors,
  scorecards,
  ui,
  userSettings,
  baltoAcademy,
}

export default reducers
