import React from 'react'
import { Input, Checkbox, Popup } from 'semantic-ui-react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { SortButton } from '../../SortButton'

export const AdvancedTableHeader = ({
  actions,
  columns,
  orderBy,
  order,
  onRequestSort,
  updateSearch,
  stickyAction = false,
  truncate = false,
  wrapColumnContent = true,
  searchFilters = {},
  compactHeader = false,
  isBulkSelectable,
  handleBulkSelectAll,
  selectedRows,
  allRowsSelected,
  reorderable,
  filteredRows,
  handleDeselectAll,
  dataGrid,
}) => {
  const actionsWidthCalculated = actions.length * 32
  const sharedHeadRowClasses = { 'compact-header': compactHeader }
  const noRowsVisible = isEmpty(filteredRows)
  const someRowsSelected = isBulkSelectable && !isEmpty(selectedRows)

  const createSortHandler = (accessor) => (event) => {
    onRequestSort(event, accessor)
  }

  return (
    <thead>
      <tr>
        {isBulkSelectable && (
          <th
            className={classNames('bulk-selectable-column', sharedHeadRowClasses, {
              'sticky-column': dataGrid,
            })}
          >
            {!noRowsVisible && handleBulkSelectAll && (
              <div className="vertical-center">
                <div
                  className="flex-align-center small-gap"
                  data-testid={allRowsSelected ? 'bulk-deselect-all' : 'bulk-select-all'}
                >
                  <Popup
                    inverted
                    content={allRowsSelected ? 'Deselect All' : 'Select All'}
                    trigger={
                      <Checkbox
                        indeterminate={!allRowsSelected && !isEmpty(selectedRows)}
                        checked={allRowsSelected}
                        onChange={handleBulkSelectAll}
                      />
                    }
                  />
                </div>
              </div>
            )}
            {someRowsSelected && (
              <Popup
                inverted
                content="Deselect All"
                trigger={
                  <Checkbox
                    indeterminate={!allRowsSelected && !isEmpty(selectedRows)}
                    checked={allRowsSelected}
                    onChange={handleDeselectAll}
                  />
                }
              />
            )}
          </th>
        )}
        {reorderable && <th className={classNames(sharedHeadRowClasses)} />}
        {columns.map((headCell) => {
          const { isSortable = true, headerAlignment } = headCell

          return (
            <th
              key={headCell.accessor}
              data-testid={`th--${headCell.accessor}`}
              className={classNames({
                ...sharedHeadRowClasses,
                'sticky-column': headCell.sticky,
                'sticky-column-selectable-offset': isBulkSelectable && dataGrid && headCell.sticky,
                'no-wrap': !wrapColumnContent,
                'full-width-row': headCell.fullWidthRow,
                [`${headerAlignment}-aligned`]: headerAlignment && headerAlignment,
              })}
              style={headCell.color ? { borderBottom: `2px solid ${headCell.color}` } : {}}
            >
              {isSortable ? (
                <SortButton
                  label={headCell.label}
                  handleClick={createSortHandler(headCell.accessor)}
                  dataType={headCell.accessor}
                  sortBy={orderBy}
                  asc={order === 'asc'}
                  data-testid={`sort-button-${headCell.accessor}`}
                  truncate={truncate}
                />
              ) : (
                <div data-testid={`static-header-${headCell.accessor}`}>{headCell.label}</div>
              )}
            </th>
          )
        })}
        {!isEmpty(actions) && (
          <th
            style={{
              textAlign: 'center',
              width: actionsWidthCalculated,
              minWidth: actionsWidthCalculated,
            }}
            className={classNames({
              'sticky-action': stickyAction,
              'no-wrap': !wrapColumnContent,
            })}
          >
            Actions
          </th>
        )}
      </tr>
      {columns.some((column) => column.isSearchable) && (
        <tr>
          {isBulkSelectable && <th className={classNames({ 'sticky-column': dataGrid })} />}
          {reorderable && <th />}
          {columns.map((headCell) => {
            if (headCell.isSearchable) {
              return (
                <th
                  key={headCell.accessor}
                  className={classNames({
                    'sticky-column': headCell.sticky,
                    'sticky-column-selectable-offset':
                      isBulkSelectable && dataGrid && headCell.sticky,
                  })}
                >
                  <Input
                    data-testid={`${headCell.label}-search`}
                    type="text"
                    icon="search"
                    iconPosition="left"
                    className="search-input"
                    placeholder={`Search ${headCell.label}...`}
                    value={searchFilters[headCell.accessor] || ''}
                    onChange={(e) => {
                      updateSearch(headCell.accessor, e.target.value)
                    }}
                  />
                </th>
              )
            }

            return <th key={headCell.accessor} />
          })}
          {!isEmpty(actions) && (
            <th key="empty-cell" className={classNames({ 'sticky-action': stickyAction })} />
          )}
        </tr>
      )}
    </thead>
  )
}
