// Action Types
export const SET_LOADING = 'baltoAcademy/setLoading'
export const SET_COURSES = 'baltoAcademy/setCourses'
export const SET_ACTIVE_COURSE_URL = 'baltoAcademy/setActiveCourseURL'

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setCourses = (payload) => ({ type: SET_COURSES, payload })
export const setActiveCourseURL = (payload) => ({ type: SET_ACTIVE_COURSE_URL, payload })

// State
export const initialState = {
  loading: false,
  courses: [],
  activeCourseURL: '',
}

// Reducer
export default function baltoAcademyReducer(state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case SET_LOADING:
      return { ...state, loading: payload }
    case SET_COURSES:
      return { ...state, courses: payload }
    case SET_ACTIVE_COURSE_URL:
      return { ...state, activeCourseURL: payload }
    default:
      return state
  }
}
