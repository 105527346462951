import React from 'react'
import Select, { createFilter } from 'react-select'

import {
  multiSelectStyles,
  selectTheme,
  Option,
  MultiValue,
  MenuList,
  ExclusiveMenuList,
} from '../helpers/selectHelpers'

export const MultiSelect = ({
  loading,
  options = [],
  fixedWidth,
  condenseOptions = true,
  maxUnCondensedOptions = 0,
  removeDropdown = false,
  isExclusive = false,
  style,
  ...props
}) => {
  if (maxUnCondensedOptions) {
    condenseOptions = props.value?.length > maxUnCondensedOptions
  }
  const styles = multiSelectStyles(fixedWidth)

  const components = {
    Option,
    ...(condenseOptions && { MultiValue }),
    ...(isExclusive ? { MenuList: ExclusiveMenuList } : { MenuList }),
    ...(removeDropdown && { DropdownIndicator: () => null, IndicatorSeparator: () => null }),
  }

  return (
    <Select
      placeholder={
        props.placeholderPill ? (
          <div className="option-label">{props.placeholderPill}</div>
        ) : (
          props.placeholder
        )
      }
      isMulti
      isClearable
      isSearchable
      allowSelectAll={props?.allowSelectAll}
      closeMenuOnSelect={false}
      escapeClearsValue={false}
      hideSelectedOptions={false}
      filterOption={createFilter({ ignoreAccents: false })}
      backspaceRemovesValue={false}
      options={options}
      styles={styles}
      theme={(theme) => selectTheme(theme)}
      components={components}
      isLoading={loading}
      selection
      isDisabled={props.disabled}
      {...props}
    />
  )
}
