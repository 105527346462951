import React from 'react'
import { IconSortAscending, IconSortDescending } from '@tabler/icons-react'
import classNames from 'classnames'

import './SortButton.scss'

export const SortButton = ({
  label,
  dataType,
  handleClick,
  sortBy,
  asc,
  className,
  tagCategoryName,
  hideIcon,
  truncate,
  ...otherProps
}) => {
  const sorted = sortBy === dataType || sortBy === tagCategoryName
  let icon = <IconSortAscending data-testid="no-sort" />

  if (sorted) {
    if (asc) {
      icon = <IconSortAscending data-testid="ascending" />
    } else {
      icon = <IconSortDescending data-testid="descending" />
    }
  }

  return (
    <button
      type="button"
      onClick={() => handleClick && handleClick(dataType, tagCategoryName)}
      className={classNames('sort-button', className, { 'show-on-hover': !sorted })}
      {...otherProps}
    >
      <div className={classNames({ truncate })}>{label}</div>
      {!hideIcon && icon}
    </button>
  )
}
