import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createIntegration } from '@/reducers/integrations/integrations.actions'
import { useHistory } from 'react-router'
import { getOrganizationOptions } from '@/utils/helpers'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { closeModal } from '@/reducers/ui/ui.redux'
import { getSchema } from './integrations.schema'

export const IntegrationsForm = () => {
  const { organizations } = useSelector((state) => state)
  const organizationOptions = getOrganizationOptions(organizations)
  const typeOptions = [
    { label: 'RingCentral Office', value: 'ringcentral' },
    { label: 'RingCentral Engage', value: 'ringcentral_engage' },
    { label: 'Genesys Cloud', value: 'genesys' },
    { label: 'Five9', value: 'five9' },
    { label: '8x8 VCC', value: '8x8' },
    { label: 'Amazon Connect', value: 'amazon_connect' },
    { label: 'Zoom', value: 'zoom' },
    { label: 'Zoom Video', value: 'zoom_video' },
    { label: 'Close', value: 'close' },
    { label: 'AirCall', value: 'aircall' },
    { label: 'TLD', value: 'tld' },
    { label: 'Trackdrive', value: 'trackdrive' },
    { label: 'Thrio', value: 'thrio' },
    { label: 'Nice', value: 'nice' },
    { label: 'Vonage VBE', value: 'vonage_vbe' },
    { label: 'TCN', value: 'tcn' },
    { label: 'API', value: 'api' },
    { label: 'Regal', value: 'regal' },
    { label: 'Dialpad', value: 'dialpad' },
    { label: 'Call Tracking Metrics', value: 'call_tracking_metrics' },
  ]
  const history = useHistory()
  const dispatch = useDispatch()
  const schema = getSchema({
    typeOptions,
    organizationOptions,
  })

  return (
    <AbstractForm
      schema={schema}
      buttonLabel="Create"
      onSubmit={({ validateOnMount, ...values }) => {
        // strip out validateOnMount prop with destructuring
        dispatch(createIntegration({ values, push: history.push }))
        dispatch(closeModal())
      }}
    />
  )
}
