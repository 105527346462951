import React from 'react'
import { Accordion, Dropdown, Loader } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import {
  IconLayoutDashboard,
  IconTelescope,
  IconUsersGroup,
  IconPlug,
  IconRoute,
  IconChartPie,
  IconTrophy,
  IconListCheck,
  IconPlaneTilt,
  IconSchool,
  IconUserCircle,
  IconChevronDown,
  IconLogout2,
  IconRefresh,
  IconChevronsLeft,
  IconChevronsRight,
  IconPlaylist,
  IconNotes,
} from '@tabler/icons-react'
import { toggleSidebarCollapsed } from '@/reducers/ui/ui.redux'
import { unauthenticateUser } from '@/reducers/auth/currentUser.actions'

import {
  reportLinks,
  scorecardLinks,
  copilotScorecardLinks,
  realTimeLinks,
  gamificationLinks,
  getFilteredScorecardLinks,
} from './GlobalSidebar.helpers'
import { BaltoLogo } from '../../BaltoLogo'

import './globalSidebar.scss'

function SidebarItemsAccordion({ items }) {
  return (
    <ul className="balto-sidebar__collapse-menu--content">
      {items.map((item) => (
        <li key={`${item.path}${item.label}`}>
          <NavLink key={item.path} to={item.path} exact={item.exact} className="menu-link">
            {item.label}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

function SidebarItemsDropdown({ items }) {
  return (
    <>
      {items.map((item) => (
        <NavLink key={item.path} to={item.path} exact={item.exact} className="item">
          {item.label}
        </NavLink>
      ))}
    </>
  )
}

const GlobalSidebarComponent = ({ flags, ldClient }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { sidebarCollapsed } = useSelector((state) => state.ui)
  const {
    organizationid,
    real_time_management_access: deprecatedRtcAccess,
    realtime_coaching_access: userRealtimeCoachingAccess,
    org_realtime_coaching_access: orgRealtimeCoachingAccess,
    edit_qa: qaAccess,
    edit_qa_copilot: qaCopilotAccess,
    game_admin: isGameAdmin,
    game_operator: isGameOperator,
    game_monitor: isGameMonitor,
    hierarchy_manager: isHierarchyManager,
    organization_name: organizationName,
    edit_config: playbookEditAccess,
    own_organization_id,
  } = useSelector((state) => state.currentUser)

  const { currentOrganization } = useSelector((state) => state)
  const {
    loading: customBrandingLoading,
    customBranding,
    imageCache,
  } = useSelector((state) => state.customBranding)

  const { userOrgHierarchy } = useSelector((state) => state.orgHierarchy)

  const isAdmin = organizationid === 1
  const isGamificationLink =
    location.pathname.includes('gamification') || location.pathname.includes('leaderboards')
  const showGamificationLinks = isGameAdmin || isGameOperator || isGameMonitor
  const shouldShowCustomLogo = customBranding.iconDarkUrl || customBranding.iconLightUrl
  const customLogoUrl = `${shouldShowCustomLogo}?${imageCache}`
  const childOrgSelected = isHierarchyManager && organizationid !== own_organization_id

  // user + org must have access, and need to hide for hierarchy managers impersonating child org (no RTC support for org hierarchy yet)
  const showRealtimeCoaching =
    (userRealtimeCoachingAccess || deprecatedRtcAccess) &&
    orgRealtimeCoachingAccess &&
    !childOrgSelected

  const filteredScorecardLinks = getFilteredScorecardLinks(scorecardLinks, flags, currentOrganization) // prettier-ignore
  const filteredCopilotScorecardLinks = getFilteredScorecardLinks(copilotScorecardLinks, flags, currentOrganization, isHierarchyManager) // prettier-ignore

  const showNotetaker = playbookEditAccess && currentOrganization.call_summarization_enabled

  const handleLogout = (e) => {
    e.preventDefault()

    history.push('/login')
    // if the user is prompted to confirm and cancels, we don't want to clear
    if (history.location.pathname === '/login') {
      dispatch(unauthenticateUser({ history, location, ldClient }))
    }
  }

  const handleCollapse = (e) => {
    e.preventDefault()
    dispatch(toggleSidebarCollapsed(!sidebarCollapsed))
  }

  const ReportingPanel = [
    {
      key: 'Reporting',
      title: {
        content: (
          <>
            <IconChartPie />
            <span className="balto-sidebar__collapse-menu--header-title">Reporting</span>
            <IconChevronDown className="caret-icon" />
          </>
        ),
        icon: false,
      },
      content: {
        content: <SidebarItemsAccordion items={reportLinks} />,
      },
    },
  ]

  const QaScorecardsPanel = [
    {
      key: 'QAScorecards',
      title: {
        content: (
          <>
            <IconListCheck />
            <span className="balto-sidebar__collapse-menu--header-title">Real-Time QA</span>
            <IconChevronDown className="caret-icon" />
          </>
        ),
        icon: false,
      },
      content: {
        content: <SidebarItemsAccordion items={filteredScorecardLinks} />,
      },
    },
  ]

  const QaCopilotScorecardsPanel = [
    {
      key: 'QaCopilotScorecards',
      title: {
        content: (
          <>
            <IconPlaneTilt />
            <span className="balto-sidebar__collapse-menu--header-title">QA Copilot</span>
            <IconChevronDown className="caret-icon" />
          </>
        ),
        icon: false,
      },
      content: {
        content: <SidebarItemsAccordion items={filteredCopilotScorecardLinks} />,
      },
    },
  ]

  const GamificationPanel = [
    {
      key: 'Gamification',
      title: {
        content: (
          <>
            <IconTrophy />
            <span className="balto-sidebar__collapse-menu--header-title">Gamification</span>
            <IconChevronDown className="caret-icon" />
          </>
        ),
        icon: false,
      },
      content: {
        content: <SidebarItemsAccordion items={gamificationLinks} />,
      },
    },
  ]

  const RealTimeCoachingPanel = [
    {
      key: 'RealTimeCoaching',
      title: {
        content: (
          <>
            <IconSchool />
            <span className="balto-sidebar__collapse-menu--header-title">Real-Time Coaching</span>
            <IconChevronDown className="caret-icon" />
          </>
        ),
        icon: false,
      },
      content: {
        content: <SidebarItemsAccordion items={realTimeLinks} />,
      },
    },
  ]

  const selectOrg = (orgID, orgName) => {
    // update local storage
    const user = JSON.parse(localStorage.getItem('User'))
    user.organizationid = orgID
    user.organization_name = orgName
    localStorage.setItem('User', JSON.stringify(user))

    // load the command-center page (redux will be updated after loading with local storage user)
    // we go to command-center page instead of just reloading current page since the user may be
    // at a resource they can no longer view from their new org, which causes errors
    window.location.assign('/command-center')
  }

  const createHierarchy = (root) => {
    const activeOrg = root && root.organization_id === organizationid
    return (
      root && (
        <li key={root.organization_id}>
          <div className="menu-container">
            <a
              id={root.organization_id}
              href="#"
              title={root.name}
              onClick={activeOrg ? () => {} : () => selectOrg(root.organization_id, root.name)}
              className={`sub-org ${activeOrg ? 'active' : ''}`}
            >
              {`${root.name}`}
            </a>
          </div>
          {root?.children && (
            <ul style={{ listStyleType: 'none', paddingLeft: '1rem' }}>
              {root.children.map((child) => createHierarchy(child))}
            </ul>
          )}
        </li>
      )
    )
  }

  const OrgHierarchyPanel = [
    {
      key: 'OrgHierarchy',
      title: {
        content: (
          <>
            <IconRefresh />
            <span className="balto-sidebar__collapse-menu--header-title">Switch Organization</span>
            <IconChevronDown className="caret-icon" />
          </>
        ),
        icon: false,
      },
      content: {
        content: (
          <ul className="balto-sidebar__collapse-menu--content">
            {createHierarchy(userOrgHierarchy)}
          </ul>
        ),
      },
    },
  ]

  return (
    <aside className={`balto-sidebar ${sidebarCollapsed ? 'collapse' : ''}`}>
      <div className="balto-sidebar__collapse-link">
        <NavLink to="/command-center">
          {customBrandingLoading ? (
            <Loader inline active style={{ marginRight: '1rem' }} />
          ) : (
            <>
              {shouldShowCustomLogo ? (
                <img src={customLogoUrl} alt="App Logo" className="custom-app-logo" />
              ) : (
                <BaltoLogo
                  height={18}
                  width={24}
                  fill="#ffffff"
                  style={{ margin: '0 1rem 0 -0.25rem' }}
                />
              )}
            </>
          )}
          <div className="balto-sidebar__header-link--label">
            <div>
              {customBrandingLoading ? '' : customBranding.customBrandingDisplayName || 'Balto'}
            </div>
            <sub className="org-subheading">{organizationName}</sub>
          </div>
        </NavLink>
        {!sidebarCollapsed && (
          <>
            <div onClick={handleCollapse} className="collapse-btn">
              <IconChevronsLeft />
            </div>
          </>
        )}
        {sidebarCollapsed && (
          <div onClick={handleCollapse} className="collapse-btn collapsed">
            <IconChevronsRight />
          </div>
        )}
      </div>

      <NavLink to="/command-center" className="balto-sidebar__title-link">
        <IconLayoutDashboard />
        <span className="balto-sidebar__collapse-menu--header-title">Command Center</span>
      </NavLink>

      <NavLink to="/call-explorer" className="balto-sidebar__title-link">
        <IconTelescope />
        <span className="balto-sidebar__collapse-menu--header-title">Call Explorer</span>
      </NavLink>

      {flags.playlistsEnabled && (
        <NavLink to="/playlists" className="balto-sidebar__title-link">
          <IconPlaylist />
          <span className="balto-sidebar__collapse-menu--header-title">Playlists</span>
        </NavLink>
      )}

      {/* Organization and Calls Links */}
      {isAdmin && (
        <>
          <NavLink to="/organizations" className="balto-sidebar__title-link">
            <IconUsersGroup />
            <span className="balto-sidebar__collapse-menu--header-title">Organizations</span>
          </NavLink>
          <NavLink to="/integrations" className="balto-sidebar__title-link">
            <IconPlug />
            <span className="balto-sidebar__collapse-menu--header-title">Integrations</span>
          </NavLink>
        </>
      )}

      {/* Realtime Notetaker Links */}
      {showNotetaker && (
        <NavLink to="/realtime-notetaker" className="balto-sidebar__title-link">
          <IconNotes />
          <span className="balto-sidebar__collapse-menu--header-title">Notetaker</span>
        </NavLink>
      )}

      {/* Playbooks Links */}
      <NavLink to="/playbooks" className="balto-sidebar__title-link">
        <IconRoute />
        <span className="balto-sidebar__collapse-menu--header-title">Playbooks</span>
      </NavLink>

      {/* User Links */}
      {!isAdmin && (
        <NavLink to="/users" className="balto-sidebar__title-link">
          <IconUserCircle />
          <span className="balto-sidebar__collapse-menu--header-title">Users</span>
        </NavLink>
      )}

      {/* Reporting Links */}
      {!sidebarCollapsed && (
        <Accordion
          defaultActiveIndex={location.pathname.startsWith('/reports') ? 0 : -1}
          panels={ReportingPanel}
        />
      )}

      {sidebarCollapsed && (
        <Dropdown
          icon={<IconChartPie />}
          pointing="left"
          className="link item sidebar-collapse-trigger"
        >
          <Dropdown.Menu>
            <SidebarItemsDropdown items={reportLinks} />
          </Dropdown.Menu>
        </Dropdown>
      )}

      {/* Leaderboards Links Expanded Sidebar */}
      {!sidebarCollapsed &&
        (isAdmin || showGamificationLinks ? (
          <Accordion panels={GamificationPanel} defaultActiveIndex={isGamificationLink ? 0 : -1} />
        ) : (
          <NavLink to="/leaderboards" className="balto-sidebar__title-link">
            <IconTrophy />
            <span className="balto-sidebar__collapse-menu--header-title">Leaderboards</span>
          </NavLink>
        ))}

      {/* Leaderboards Links Collapsed Sidebar */}
      {sidebarCollapsed &&
        (isAdmin || showGamificationLinks ? (
          <Dropdown
            icon={<IconTrophy />}
            pointing="left"
            className="link item sidebar-collapse-trigger"
          >
            <Dropdown.Menu>
              <SidebarItemsDropdown items={gamificationLinks} />
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <NavLink to="/leaderboards" className="balto-sidebar__title-link">
            <IconTrophy />
            <span className="balto-sidebar__collapse-menu--header-title">Leaderboards</span>
          </NavLink>
        ))}

      {/* QA Scorecards Links */}
      {((currentOrganization.qa_enabled && qaAccess) || isAdmin) && !sidebarCollapsed && (
        <Accordion
          panels={QaScorecardsPanel}
          defaultActiveIndex={location.pathname.includes('scorecards') ? 0 : -1}
        />
      )}
      {((currentOrganization.qa_enabled && qaAccess) || isAdmin) && sidebarCollapsed && (
        <Dropdown
          icon={<IconListCheck />}
          pointing="left"
          className="link item sidebar-collapse-trigger"
        >
          <Dropdown.Menu>
            <SidebarItemsDropdown items={scorecardLinks} />
          </Dropdown.Menu>
        </Dropdown>
      )}

      {/* QA Copilot Scorecard Links */}
      {((currentOrganization.qa_copilot_enabled && qaCopilotAccess) || isAdmin) &&
        !sidebarCollapsed && (
          <Accordion
            panels={QaCopilotScorecardsPanel}
            defaultActiveIndex={location.pathname.includes('qa-copilot') ? 0 : -1}
          />
        )}
      {((currentOrganization.qa_copilot_enabled && qaCopilotAccess) || isAdmin) &&
        sidebarCollapsed && (
          <Dropdown
            icon={<IconPlaneTilt />}
            pointing="left"
            className="link item sidebar-collapse-trigger"
          >
            <Dropdown.Menu>
              <SidebarItemsDropdown items={copilotScorecardLinks} />
            </Dropdown.Menu>
          </Dropdown>
        )}

      {/* Real-Time Coaching Links */}
      {showRealtimeCoaching && !sidebarCollapsed && (
        <Accordion
          defaultActiveIndex={location.pathname.includes('realtime_coaching') ? 0 : -1}
          panels={RealTimeCoachingPanel}
        />
      )}

      {showRealtimeCoaching && sidebarCollapsed && (
        <Dropdown
          icon={<IconSchool />}
          pointing="left"
          className="link item sidebar-collapse-trigger"
        >
          <Dropdown.Menu>
            <SidebarItemsDropdown items={realTimeLinks} />
          </Dropdown.Menu>
        </Dropdown>
      )}

      {!sidebarCollapsed && (
        <div className="balto-sidebar__footer">
          {!sidebarCollapsed && isHierarchyManager && (
            <Accordion panels={OrgHierarchyPanel} defaultActiveIndex={-1} />
          )}
          <a className="balto-sidebar__title-link" onClick={handleLogout}>
            <IconLogout2 />
            <span
              data-testid="sign-out-button"
              className="balto-sidebar__collapse-menu--header-title"
            >
              Sign out
            </span>
          </a>
        </div>
      )}
    </aside>
  )
}

export const GlobalSidebar = withLDConsumer()(GlobalSidebarComponent)
