import produce from 'immer'

import { GPT_DASHBOARD_TYPE } from '@/views/CommandCenter/widgets/gptWidgets'

// Action Types
export const SET_USER_DASHBOARD_SETTINGS = 'userSettings/setDashboardSettings'
export const SET_LOADING = 'userSettings/setLoading'

// Action Creators
export const setUserDashboardSettings = (payload) => ({ type: SET_USER_DASHBOARD_SETTINGS, payload }) // prettier-ignore
export const setLoading = (payload) => ({ type: SET_LOADING, payload })

// State
export const initialState = {
  dashboardSettings: {
    [GPT_DASHBOARD_TYPE]: [],
  },
  loading: {
    dashboardSettings: false,
  },
}

// Reducer
export default function userSettingsReducer(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_USER_DASHBOARD_SETTINGS:
        newState.dashboardSettings[action.payload.dashboardType] = payload.data
        break
      case SET_LOADING:
        newState.loading = { ...newState.loading, ...payload }
        break
      default:
        break
    }
  })
}
