import React from 'react'

import IntegrationsEditPage from '@/views/Integrations/IntegrationsEditPage'
import IntegrationsPage from '@/views/Integrations/IntegrationsPage'
import OrgHierarchyPage from '@/views/OrgHierarchy/OrgHierarchyPage'

const IndexRedirect = React.lazy(() => import('./IndexRedirect'))
const CommandCenterPage = React.lazy(() => import('../views/CommandCenter/CommandCenterPage'))
const PlaybooksPage = React.lazy(() => import('../views/Playbooks/PlaybooksPage'))
const PlaybookEditorPage = React.lazy(() => import('../views/Playbooks/PlaybookEditorPage'))
const OrganizationsPage = React.lazy(() => import('../views/Organizations/OrganizationsPage'))
const OrganizationPage = React.lazy(() => import('../views/Organizations/OrganizationPage'))
const TagPage = React.lazy(() => import('../views/Organizations/TagPage'))
const OrganizationFlagsPage = React.lazy(() => import('../views/Organizations/OrganizationFlagsPage')) // prettier-ignore
const CallSearchPage = React.lazy(() => import('../views/Calls/CallSearchPage'))
const CallSearchProxy = React.lazy(() => import('../views/Calls/CallSearchProxy'))
const CallExplorerPage = React.lazy(() => import('../views/Calls/CallExplorerPage'))
const KeywordRepositoryPage = React.lazy(() => import('../views/KeywordRepository/KeywordRepositoryPage')) // prettier-ignore
const LeaderboardsPage = React.lazy(() => import('../views/Leaderboards/LeaderboardsPage'))
const QACopilotPage = React.lazy(() => import('../views/QACopilot/QACopilotPage'))
const AnalyticsPage = React.lazy(() => import('../views/Analytics/AnalyticsPage'))
const ScorecardsPage = React.lazy(() => import('../views/Scorecards/Scorecards'))
const RealtimeCoachingPage = React.lazy(() => import('../views/RealtimeCoaching/RealtimeCoaching'))
const ScorecardEditorPage = React.lazy(() => import('../views/Scorecards/ScorecardEditorWrapper'))
const ViewScoresPage = React.lazy(() => import('../views/Scorecards/ViewScoresPage/ViewScoresPage'))
const ViewManualScoringPage = React.lazy(() => import('../views/Scorecards/ViewManualScoringPage/ViewManualScoringPage')) // prettier-ignore
const ScorecardDashboardPage = React.lazy(() => import('../views/Scorecards/DashboardPage/DashboardPage')) // prettier-ignore
const ScorecardExportPage = React.lazy(() => import('../views/Scorecards/ExportsPage/ExportsDashboard')) // prettier-ignore
const ScorecardActivityPage = React.lazy(() => import('@/views/Scorecards/ActivitiesPage/QAActivitiesPage')) // prettier-ignore
const ChallengesPage = React.lazy(() => import('@/views/Gamification/Challenges/ChallengesPage/ChallengesPage')) // prettier-ignore
const BadgesPage = React.lazy(() => import('@/views/Gamification/Badges/BadgesPage'))
const BadgeWizard = React.lazy(() => import('@/views/Gamification/Badges/BadgeWizard/BadgeWizard'))
const ChallengeDetailPage = React.lazy(() => import('@/views/Gamification/Challenges/ChallengeDetailPage/ChallengeDetailPage')) // prettier-ignore
const BadgeDetailPage = React.lazy(() => import('@/views/Gamification/Badges/BadgeDetailPage/BadgeDetailPage')) // prettier-ignore
const CopilotDashboardPage = React.lazy(() => import('@/views/QACopilot/CopilotDashboard/CopilotDashboardPage')) // prettier-ignore
const EnterpriseDashboardPage = React.lazy(() => import('@/views/QACopilot/CopilotDashboard/EnterpriseDashboardPage')) // prettier-ignore
const DisputesTable = React.lazy(() => import('@/views/Scorecards/Disputes/DisputesTable'))
const PlaylistPage = React.lazy(() => import('@/views/Playlists/PlaylistPage'))
const PlaylistsPage = React.lazy(() => import('@/views/Playlists/PlaylistsPage'))
const RealtimeNotetakerPage = React.lazy(
  () => import('@/views/RealtimeNotetaker/RealtimeNotetakerPage')
)
const FeatureFeedbackPage = React.lazy(() => import('@/views/FeatureFeedback/FeatureFeedbackPage')) // prettier-ignore
export const protectedRoutes = [
  {
    path: '/',
    Component: IndexRedirect,
  },

  {
    path: '/command-center',
    Component: CommandCenterPage,
    pageTitle: 'Command Center',
  },

  {
    path: '/playbooks',
    Component: PlaybooksPage,
    pageTitle: 'Playbooks',
  },

  // New playbook editor
  {
    path: '/playbooks/:id',
    Component: PlaybookEditorPage,
    pageTitle: 'Redirecting...',
  },
  {
    path: '/playbooks/:id/:section',
    Component: PlaybookEditorPage,
    pageTitle: 'Playbook Editor',
  },
  {
    path: '/playbooks/:id/:section/:entryId',
    Component: PlaybookEditorPage,
    pageTitle: 'Playbook Editor',
  },

  {
    path: '/reports',
    Component: AnalyticsPage,
  },
  {
    path: '/reports/:section',
    Component: AnalyticsPage,
  },

  {
    path: '/users',
    Component: OrganizationPage,
    pageTitle: 'Users',
  },
  {
    path: '/users/tags',
    Component: TagPage,
    pageTitle: 'User Tag Manager',
  },

  {
    path: '/leaderboards',
    Component: LeaderboardsPage,
    pageTitle: 'Leaderboards',
  },
  // QA Routes
  {
    path: '/scorecards',
    Component: ScorecardsPage,
    qaRoute: true,
    pageTitle: 'Scorecards',
  },
  {
    path: '/scorecards/dashboard',
    Component: ScorecardDashboardPage,
    qaRoute: true,
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/scorecards/dashboard/overview',
    Component: ScorecardDashboardPage,
    qaRoute: true,
    componentProps: { tab: 'Overview' },
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/scorecards/dashboard/live',
    Component: ScorecardDashboardPage,
    qaRoute: true,
    componentProps: { tab: 'Live Feed' },
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/scorecards/view_scores',
    Component: ViewScoresPage,
    componentProps: { copilot: false },
    qaRoute: true,
    pageTitle: 'Scores',
  },
  {
    path: '/scorecards/exports',
    Component: ScorecardExportPage,
    qaRoute: true,
    pageTitle: 'Export Scores',
  },
  {
    path: '/scorecards/activity',
    Component: ScorecardActivityPage,
    qaRoute: true,
    pageTitle: 'QA Activity',
  },
  {
    path: '/scorecards/edit_config/:scorecard_config_id',
    Component: ScorecardEditorPage,
    qaRoute: true,
    pageTitle: 'Scorecard Editor',
  },
  {
    path: '/scorecards/edit_config/:scorecard_config_id/edit_section/:scorecard_section_id',
    Component: ScorecardEditorPage,
    qaRoute: true,
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/scorecards/manual_scoring',
    Component: ViewManualScoringPage,
    qaRoute: true,
    pageTitle: 'Manual Scoring',
  },
  {
    path: '/scorecards/disputes',
    Component: DisputesTable,
    qaRoute: true,
    pageTitle: 'Agent Disputes',
  },
  // Copilot routes
  {
    path: '/qa-copilot',
    Component: ScorecardsPage,
    qaCopilotRoute: true,
    pageTitle: 'QA Copilot Scorecards',
  },
  {
    path: '/qa-copilot/dashboard',
    Component: CopilotDashboardPage,
    qaCopilotRoute: true,
    pageTitle: 'Copilot Dashboard',
  },
  {
    path: '/qa-copilot/enterprise',
    Component: EnterpriseDashboardPage,
    qaCopilotRoute: true,
    pageTitle: 'Copilot Enterprise Dashboard',
  },
  {
    path: '/qa-copilot/dashboard/overview',
    Component: ScorecardDashboardPage,
    qaCopilotRoute: true,
    componentProps: { tab: 'Overview' },
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/qa-copilot/dashboard/live',
    Component: ScorecardDashboardPage,
    qaCopilotRoute: true,
    componentProps: { tab: 'Live Feed' },
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/qa-copilot/view_scores',
    Component: ViewScoresPage,
    componentProps: { copilot: true },
    qaCopilotRoute: true,
    pageTitle: 'Scores',
  },
  {
    path: '/qa-copilot/exports',
    Component: ScorecardExportPage,
    qaCopilotRoute: true,
    pageTitle: 'Export Scores',
  },
  {
    path: '/qa-copilot/activity',
    Component: ScorecardActivityPage,
    qaCopilotRoute: true,
    pageTitle: 'QA Activity',
  },
  {
    path: '/qa-copilot/edit_config/:scorecard_config_id',
    Component: ScorecardEditorPage,
    qaCopilotRoute: true,
    pageTitle: 'Scorecard Editor',
  },
  {
    path: '/qa-copilot/edit_config/:scorecard_config_id/edit_section/:scorecard_section_id',
    Component: ScorecardEditorPage,
    qaCopilotRoute: true,
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/qa-copilot/manual_scoring',
    Component: ViewManualScoringPage,
    qaCopilotRoute: true,
    pageTitle: 'Manual Scoring',
  },
  {
    path: '/qa-copilot/disputes',
    Component: DisputesTable,
    qaCopilotRoute: true,
    pageTitle: 'Agent Disputes',
  },
  {
    path: '/qa-copilot/:id',
    Component: QACopilotPage,
    qaCopilotRoute: true,
    pageTitle: 'QA Copilot',
  },

  // Gamification Routes
  {
    path: '/gamification/challenges',
    Component: ChallengesPage,
    pageTitle: 'Challenges',
  },
  {
    path: '/gamification/badges',
    Component: BadgesPage,
    componentProps: { tab: 'Collections' },
    pageTitle: 'Badges | Collections',
  },
  {
    path: '/gamification/badges/collections',
    Component: BadgesPage,
    componentProps: { tab: 'Collections' },
    pageTitle: 'Badges | Collections',
  },
  {
    path: '/gamification/badges/challenges',
    Component: BadgesPage,
    componentProps: { tab: 'Challenges' },
    pageTitle: 'Badges | Challenges',
  },
  {
    path: '/gamification/badges/wizard',
    Component: BadgeWizard,
    pageTitle: 'Badge Wizard',
  },
  {
    path: '/gamification/challenges/:challenge_id',
    Component: ChallengeDetailPage,
    pageTitle: 'Challenge Detail',
  },
  {
    path: '/gamification/badges/:badge_id',
    Component: BadgeDetailPage,
    pageTitle: 'Badge Detail',
  },

  // Real Time Coaching Routes
  {
    path: '/realtime_coaching/:section',
    Component: RealtimeCoachingPage,
    pageTitle: 'Real Time Coaching',
  },
  {
    path: '/realtime_coaching/alerts/:alert_config_id',
    Component: RealtimeCoachingPage,
    pageTitle: 'Real Time Alerts',
  },
  {
    path: '/realtime_coaching/reports/:subsection',
    Component: RealtimeCoachingPage,
    pageTitle: 'Real Time Reports',
  },

  // Admin Routes
  {
    path: '/organizations',
    Component: OrganizationsPage,
    adminOnly: true,
    pageTitle: 'Organizations',
  },
  {
    path: '/organizations/:organizationid',
    Component: OrganizationPage,
    adminOnly: true,
    pageTitle: 'Organization Detail',
  },
  {
    path: '/organizations/:organizationid/tags',
    Component: TagPage,
    adminOnly: true,
    pageTitle: 'Organization Tag Manager',
  },
  {
    path: '/organizations/:organizationid/flags',
    Component: OrganizationFlagsPage,
    adminOnly: true,
    pageTitle: 'Organization Flags',
  },
  {
    path: '/organizations/:organizationid/hierarchy',
    Component: OrgHierarchyPage,
    adminOnly: true,
    pageTitle: 'Organization Hierarchy',
  },
  {
    path: '/calls',
    Component: CallSearchProxy,
    adminOnly: true,
    pageTitle: 'Call Explorer',
  }, // TODO: Remove this
  {
    path: '/call-explorer/:id',
    Component: CallExplorerPage,
    pageTitle: 'Call Detail',
  },
  {
    path: '/call-explorer',
    Component: CallSearchPage,
    pageTitle: 'Call Explorer',
  },
  {
    path: '/keywords_repository/:language(english|spanish)?',
    Component: KeywordRepositoryPage,
    adminOnly: true,
    pageTitle: 'Keyword Repository',
  },
  {
    path: '/integrations',
    Component: IntegrationsPage,
    adminOnly: true,
    pageTitle: 'Integrations',
  },
  {
    path: '/integrations/edit_config/:integration_config_id',
    Component: IntegrationsEditPage,
    qaRoute: true,
    componentProps: { tab: 'Connector Config' },
    pageTitle: 'Integration Editor',
  },

  // Playlists
  {
    path: '/playlists',
    Component: PlaylistsPage,
    pageTitle: 'Playlists',
  },
  {
    path: '/playlists/:playlistUuid',
    Component: PlaylistPage,
    pageTitle: 'Playlist',
  },

  // Realtime Notetaker
  {
    path: '/realtime-notetaker',
    Component: RealtimeNotetakerPage,
    pageTitle: 'Realtime Notetaker',
  },
  {
    path: '/feature-feedback',
    Component: FeatureFeedbackPage,
    pageTitle: 'Feature Feedback',
  },
]
